import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  OAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import { auth } from "../../../firebase";
import { Container, Col, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { google, facebook, microsoft, yahooIcon } from "../../../assets/images";
import { loginandChangeHeader } from "../../../redux_toolkit/reducer/registrationReducer";
import { useDispatch } from "react-redux";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";

const CreateAccount = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [setValue]);

  const onSubmit = async (data: any) => {
    try {
      const formdata = new FormData();
      formdata.append("email", data.email);
      formdata.append("phoneNo", "+91" + data.phoneNumber);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/phone_email_register/`,
        {
          method: "POST",
          body: formdata,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem("token", responseData?.token?.access);
        navigate("/CreateAccountOTP");
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData?.msg);
      }
    } catch (error) {
      toast.error("something went wrong");
      console.error("Registration failed:", error);
    }
  };

  const validatePhone = (phone: string) => {
    return phone.length >= 10;
  };

  // Custom validation Function For email
  const validateEmail = (email: string) => {
    // Email Validation Using a Regular Expression in this ways
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser: any) => {
      setTimeout(() => {
        if (localStorage.getItem("provider") == "yahoo" && currentUser) {
          providerSSOLoginSignup({
            provider: "yahoo",
            firstName: currentUser && currentUser?.displayName?.split(" ")[0],
            lastName: currentUser && currentUser?.displayName?.split(" ")[1],
            email: currentUser && currentUser?.email,
            sso_profile_image: currentUser && currentUser?.photoURL,
            access_token: currentUser.accessToken,
          });
        }
      }, 2000);
    });
    return () => unsubscribe();
  }, []);

  async function providerSSOLoginSignup({
    provider,
    firstName,
    lastName,
    email,
    sso_profile_image,
    access_token,
  }) {
    try {
      const formdata = new FormData();
      formdata.append("provider", provider);
      formdata.append("firstName", firstName);
      formdata.append("lastName", lastName);
      formdata.append("email", email);
      formdata.append("sso_profile_image", sso_profile_image);
      formdata.append("access_token", access_token);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/sso_login_signup/`,
        {
          method: "POST",
          body: formdata,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem("token", responseData.token.access);
        localStorage.setItem("userData", JSON.stringify(responseData?.data));
        toast.success("LoggedIn Successfully");
        setTimeout(() => {
          dispatch(loginandChangeHeader());
          navigate("/");
        }, 5000);
      } else {
        const errorData = await response.json();
        toast.error(errorData?.msg);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error("something went wrong");
    }
  }

  function handleGoogleSignIn() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .then((result: any) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential: any = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        providerSSOLoginSignup({
          provider: "google",
          firstName: user && user?.displayName?.split(" ")[0],
          lastName: user && user?.displayName?.split(" ")[1],
          email: user && user?.email,
          sso_profile_image: user && user?.photoURL,
          access_token: token,
        });
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error: any) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);

        // ...
      });
  }

  async function getMicrosoftUserProfile(accessToken: string) {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      const response = await fetch(`https://graph.microsoft.com/v1.0/me`, {
        method: "GET",
        headers: myHeaders,
      });

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  function handleMicrosoftSignIn() {
    const provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      // login_hint: "mohit.appic@gmail.com",
      tenant: "f8cdef31-a31e-4b4a-93e4-5f571e91255a", // Put Tenant Id from Azure registered app,
      // prompt: "consent", // Get Consent from user to access their basic info (optional - Reommended only during SignUp)
    });
    return signInWithPopup(auth, provider)
      .then((result: any) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        getMicrosoftUserProfile(accessToken);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error: any) => {
        // ...
      });
  }

  // async function getYahooUserProfile(accessToken: string) {
  //   try {
  //     const myHeaders = new Headers();
  //     myHeaders.append("Authorization", `Bearer ${accessToken}`);

  //     const response = await fetch(
  //       `https://api.login.yahoo.com/openid/v1/userinfo`,
  //       {
  //         method: "GET",
  //         mode: "no-cors",
  //         headers: myHeaders,
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to submit the data. Please try again.");
  //     }

  //     // Handle response if necessary
  //     const data = await response.json();
  //     console.log(data, "Yahoo User Profile Data");
  //     // ...
  //   } catch (error) {
  //     // Capture the error message to display to the user
  //     console.error(error);
  //   }
  // }

  // function handleYahooSignIn() {
  //   const provider = new OAuthProvider("yahoo.com");
  //   // provider.setCustomParameters({
  //   //   // Prompt user to re-authenticate to Yahoo.
  //   //   prompt: "login",
  //   //   // Localize to English.
  //   //   language: "en",
  //   // });
  //   signInWithPopup(auth, provider)
  //     .then((result: any) => {
  //       // IdP data available in result.additionalUserInfo.profile
  //       // ...
  //       console.log(result, "&&&&&&&&&&&&&&&&&&&&&&&&&");

  //       // Yahoo OAuth access token and ID token can be retrieved by calling:
  //       const credential = OAuthProvider.credentialFromResult(result);
  //       const accessToken = credential.accessToken;
  //       const idToken = credential.idToken;
  //       console.log("******************************");
  //       console.log(credential, accessToken, idToken);

  //       getYahooUserProfile(accessToken);
  //     })
  //     .catch((error) => {
  //       // Handle error.
  //       console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
  //       console.log(error);
  //       console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
  //     });
  // }

  async function handleYahooSignIn() {
    const provider = new OAuthProvider("yahoo.com");
    localStorage.setItem("provider", "yahoo");
    await signInWithRedirect(auth, provider);
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    // autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>Create An Account</h1>
              </div>

            </Col>

          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={6} md={9} sm={12} className={`m-auto`}>
              <div className={`${cx.formBox}`}>
                <button
                  className="btn mt-3"
                  type="button"
                  onClick={handleGoogleSignIn}
                >
                  <img src={google} alt="google" /> Sign Up with Google
                </button>

                <NavLink className="btn mt-3" to={"#"}>
                  <img src={facebook} alt="facebook" /> Sign Up with Facebook
                </NavLink>
                <button
                  className="btn mt-3"
                  type="button"
                  onClick={handleMicrosoftSignIn}
                >
                  <img src={microsoft} alt="microsoft" /> Sign Up with Microsoft
                </button>
                <button
                  className="btn mt-3"
                  type="button"
                  onClick={handleYahooSignIn}
                >
                  <img src={yahooIcon} alt="yahoo" /> Sign Up with Yahoo
                </button>

                <div className={`${cx.line_box}`}>
                  <div className={`${cx.line}`}></div>
                  <div className={`${cx.line_content}`}>
                    or sign up with Phone No.
                  </div>
                </div>

                <Form>
                  <Form.Group className="mb-3" controlId="phone">
                    <Form.Label>
                      Phone No.<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Write your Number"
                      {...register("phoneNumber", {
                        required: true,
                        validate: validatePhone,
                      })}
                      defaultValue=""
                    />
                    {errors.phoneNumber &&
                      errors.phoneNumber.type === "required" && (
                        <span className="text-danger">
                          Phone number is required.
                          <span className="text-danger">*</span>
                        </span>
                      )}
                    {errors.phoneNumber &&
                      errors.phoneNumber.type === "validate" && (
                        <span className="text-danger">
                          Please enter a valid phone number.
                          <span className="text-danger">*</span>
                        </span>
                      )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>
                      Email<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Write your Email"
                      {...register("email", {
                        required: true,
                        validate: validateEmail,
                      })}
                      defaultValue=""
                    />
                    {errors.email && errors.email.type === "required" && (
                      <span className="text-danger">
                        Email is required.<span className="text-danger">*</span>
                      </span>
                    )}
                    {errors.email && errors.email.type === "validate" && (
                      <span className="text-danger">
                        Please enter a valid email address.
                        <span className="text-danger">*</span>
                      </span>
                    )}
                  </Form.Group>

                  <Row>
                    <div className="col-md-6 mb-2">
                      <Button
                        className="btn save"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit
                      </Button>
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </div>
                    <div className="col-md-6 mb-2">
                      <NavLink to={"/login"} className="btn cancel">
                        Back
                      </NavLink>
                    </div>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CreateAccount;
