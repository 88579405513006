import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import "react-toastify/dist/ReactToastify.css";
import { Container, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";

const CreateAccountOTP = () => {
  const [email, setEmail] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const navigate = useNavigate();
  const [errorEmailMessage, setErrorEmailMessage] = useState("");
  const [errorPhoneMessage, setErrorPhoneMessage] = useState("");

  const handleVerification = async () => {
    try {
      const token = localStorage.getItem("token");
      const formdata = new FormData();
      formdata.append("otp_phone", phoneOtp);
      formdata.append("otp_email", email);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/otp_ver/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },

          body: formdata,
        }
      );

      if (response.ok) {
        setTimeout(() => {
          navigate("/signup");
        }, 1000);
      } else {
        const errorData = await response.json();
        setErrorEmailMessage(errorData?.error2);
        setErrorPhoneMessage(errorData?.error1);
      }
    } catch (error) {
      console.error(error, "Error verifying OTP:");
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    // autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>
      
      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>Create An Account OTP</h1>
              </div>

            </Col>

          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={5} md={6} sm={12} className={`m-auto`}>
              <div className={`${cx.onboardingBox}`}>
                <div className={`mb-4 ${cx.onboardingHeading}`}>
                  <h2>Verify</h2>
                  <p>We send 4 digit code to your Email</p>
                  <div className={`${cx.formStart}`}>
                    <div className={`${cx.otpField}`}>
                      <OtpInput
                        value={email}
                        onChange={setEmail}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    {errorEmailMessage && (
                      <p style={{ color: "red" }}>{errorEmailMessage}</p>
                    )}
                  </div>
                </div>
                <div className={`mb-4 ${cx.onboardingHeading}`}>
                  <p>We send 4 digit code to your Phone</p>
                  <div className={`${cx.formStart}`}>
                    <div className={`${cx.otpField}`}>
                      <OtpInput
                        value={phoneOtp}
                        onChange={setPhoneOtp}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    {errorPhoneMessage && (
                      <p style={{ color: "red" }}>{errorPhoneMessage}</p>
                    )}
                    <div className={`${cx.onboardingButton}`}>
                      <Button className={`btn`} onClick={handleVerification}>
                        Verify
                      </Button>

                      {/* <NavLink to={"/signup"}  className={`btn`}>Verify</NavLink> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CreateAccountOTP;
