import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { Checkbox } from "../../../components/Website/Forms";
// import { useForm } from "react-hook-form";
import JobCard from "../../../components/Website/JobCard/JobCard";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import JobToggle from "../../../components/Modals/JobToggle";

function JobFilter({
  getJobList,
  register,
  handleSubmit,
  reset,
  control,
  getValues,
  watch,
}) {
  const [subjectArea, setSubjectArea] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [contractTypes, setContractType] = useState([]);
  const [regions, setRegions] = useState([]);
  async function getSubjectArea() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/subject-areas/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setSubjectArea(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function getIndustry() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/industries/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();

      setIndustries(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function getLevelOfEducation() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/education-levels/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();

      setEducationLevels(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function getContractType() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/contract-types/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();

      setContractType(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function getRegion() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/regions/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();

      setRegions(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getSubjectArea();
    getIndustry();
    getLevelOfEducation();
    getContractType();
    getRegion();
    getJobList();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={`${cx.filterSidebar}`}>
        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>Subject Area</h5>
          <ul className={`${cx.listMenu}`}>
            {subjectArea.map((item: any) => {
              return (
                <li key={item.id}>
                  <label className={`${st.checkbox}`}>
                    <Checkbox
                      register={register}
                      value={item.id}
                      name="subject_area"
                      // getValues={getValues}
                      onSubmit={getJobList}
                    />{" "}
                    <div className={`${cx.name}`}>{item.name}</div>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className={`${cx.filterSidebar}`}>
        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>Industry</h5>
          <ul className={`${cx.listMenu}`}>
            {industries.map((item: any) => {
              return (
                <li key={item.id}>
                  <label className={`${st.checkbox}`}>
                    <Checkbox
                      register={register}
                      value={item.id}
                      name="industry"
                      // getValues={getValues}
                      onSubmit={getJobList}
                    />
                    <div className={`${cx.name}`}>{item.name}</div>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className={`${cx.filterSidebar}`}>
        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>Level of education</h5>
          <ul className={`${cx.listMenu}`}>
            {educationLevels.map((item: any) => {
              return (
                <li key={item.id}>
                  <label className={`${st.checkbox}`}>
                    <Checkbox
                      register={register}
                      value={item.id}
                      name="level_of_education"
                      // getValues={getValues}
                      onSubmit={getJobList}
                    />
                    <div className={`${cx.name}`}>{item.name}</div>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className={`${cx.filterSidebar}`}>
        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>Contract</h5>
          <ul className={`${cx.listMenu}`}>
            {contractTypes.map((item: any) => {
              return (
                <li key={item.id}>
                  <label className={`${st.checkbox}`}>
                    <Checkbox
                      register={register}
                      value={item.id}
                      name="contract_type"
                      // getValues={getValues}
                      onSubmit={getJobList}
                    />
                    <div className={`${cx.name}`}>{item.name}</div>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className={`${cx.filterSidebar}`}>
        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>Region</h5>
          <ul className={`${cx.listMenu}`}>
            {regions.map((item: any) => {
              return (
                <li key={item.id}>
                  <label className={`${st.checkbox}`}>
                    <Checkbox
                      register={register}
                      value={item.id}
                      name="region"
                      // getValues={getValues}
                      onSubmit={getJobList}
                    />
                    <div className={`${cx.name}`}>{item.name}</div>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default JobFilter;
