import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { NewsCard } from "../../../components/Website/NewsCard/NewsCard";
import st from "../../../assets/stylesheet/style.module.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SwiperCarousel from "../../../components/Website/SwiperCarousel/SwiperCarousel";
import {
  getHeroBannerAdsIndex,
  handleHerroBannerAdsIndex,
} from "../../../redux_toolkit/reducer/dataReducer";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import totalPagesInPagination from "../../../utils/totalPages";

const News = () => {
  const [data, setData] = useState([]);
  const abortController = new AbortController();
  const [heroBannerAds, setHeroBannerAds] = useState<any[]>([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(10);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/news/?page=${page}&page_size=8`,
        {
          signal: abortController.signal, // Pass the abort controller's signal to the fetch request
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const { data, count } = await response.json();
      setData(data);
      setTotalPages(totalPagesInPagination(count, 8));
    } catch (error: any) {
      if (!abortController.signal.aborted) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    getHeroBannerAdsList();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (page > 0) {
      fetchData();
    }
  }, [page]);

  async function getHeroBannerAdsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/hero-banner-ads/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setHeroBannerAds(data);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const dispatch = useDispatch();
  const ActiveHeroBannerIndex = useSelector(getHeroBannerAdsIndex);

  const handleSlideChange = (swiper) => {
    dispatch(handleHerroBannerAdsIndex(swiper.activeIndex));
  };

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <SwiperCarousel
        handleSlideChange={handleSlideChange}
        heroBannerAds={heroBannerAds}
        ActiveHeroBannerIndex={ActiveHeroBannerIndex}
        upsideDownHeader={"News"}
      />

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <NewsCard cx={cx} newsList={data} fetchData={fetchData} />
          </Row>
        </Container>
      </section>
      <section className={`${cx.pageSpace} pt-1 `}>
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <div className={`${cx.paginationBox} mt-4`}>
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChange}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default News;
