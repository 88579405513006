import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import JobCard from "../../../components/Website/JobCard/JobCard";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import JobToggle from "../../../components/Modals/JobToggle";
import JobFilter from "./JobFilter";
import { GiSettingsKnobs } from "react-icons/gi";
import {
  getHeroBannerAdsIndex,
  handleHerroBannerAdsIndex,
} from "../../../redux_toolkit/reducer/dataReducer";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination as SwiperPagination, Autoplay } from "swiper/modules";
import { allBgBanner } from "../../../assets/images";

const Job = () => {
  const navigate = useNavigate();
  const [jobList, setJobList] = useState([]);
  const [heroBannerAds, setHeroBannerAds] = useState<any[]>([]);

  useEffect(() => {
    getHeroBannerAdsList();
  });

  const { register, handleSubmit, reset, control, getValues, watch } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
  });

  async function getHeroBannerAdsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/hero-banner-ads/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setHeroBannerAds(data);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getJobList(
    { subject_area, industry, level_of_education, contract_type, region } = {
      subject_area: "",
      industry: "",
      level_of_education: "",
      contract_type: "",
      region: "",
    }
  ) {
    try {
      let query_param = Object.entries(getValues())
        .filter(([_, values]) => values)
        ?.map(([keys, values]) => {
          if (keys === "search" && values.length !== 0) {
            return `${keys}=${values}`;
          } else if (values.length > 1) {
            return values.map((item: string) => `${keys}=${item}`).join("&");
          } else {
            return values.length === 0 ? "" : `${keys}=${values}`;
          }
        })
        .join("&");
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/jobs/?${query_param}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setJobList(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const displayMsg = (redirectURL: string) => {
    setTimeout(() => {
      if (localStorage.getItem("token")) {
        navigate(redirectURL);
      } else {
        navigate("/login");
      }
    }, 400);
  };

  const [showJobToggle, setJobToggleShow] = useState(false);
  const handleJobToggleClose = () => setJobToggleShow(false);
  const handleJobToggleShow = () => setJobToggleShow(true);

  const ActiveHeroBannerIndex = useSelector(getHeroBannerAdsIndex);
  const dispatch = useDispatch();

  const handleSlideChange = (swiper) => {
    dispatch(handleHerroBannerAdsIndex(swiper.activeIndex));
  };

  return (
    <>
      <form onSubmit={handleSubmit(getJobList)}>
        <div className={`${st.header_height}`}></div>
        <section className={`${cx.create_register}`}>
          <Container fluid className="p-0">
            <Row>
              <Col md={12}>
                <Swiper
                  className="mySwiper swiper-h"
                  slidesPerView={1}
                  centeredSlides={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[SwiperPagination, Autoplay]}
                  onSlideChange={handleSlideChange}
                >
                  <SwiperSlide>
                    <div className="item">
                      <div className="m-0">
                        <img src={allBgBanner} alt="aid" />
                      </div>
                    </div>
                  </SwiperSlide>
                  {heroBannerAds?.map(({ id, title, url, image }) => {
                    return (
                      <SwiperSlide>
                        <NavLink to={url} target="_blank" key={id}>
                          <div className="item">
                            <div className="m-0">
                              <img src={image} alt="aid" />
                            </div>
                          </div>
                        </NavLink>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div
                  className={`${cx.main_heading} ${
                    ActiveHeroBannerIndex === 0 ? cx.slider_content : ""
                  }`}
                >
                  <Container>
                    <h2>Find your New Job right here</h2>
                    <div className={`${cx.searchFormBody}`}>
                      <div className={`${cx.box}`}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Keyword"
                          {...register("search")}
                        />
                      </div>
                      <button type="submit" className={`${cx.searchBtn} btn`}>
                        Search
                      </button>
                    </div>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`${cx.listingSection}`}>
          <Container>
            <Row>
              <Col md={12}>
                <div className={`${cx.post_attachBtn}`}>
                  <button
                    className="btn"
                    onClick={() => {
                      displayMsg("/post-a-job");
                    }}
                  >
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    Post New Job
                  </button>

                  <div className={`${cx.filter_mobile}`}>
                    <button
                      className="filter_btn"
                      onClick={handleJobToggleShow}
                    >
                      <GiSettingsKnobs />
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={4} className={`${cx.filterHide_onMobile} `}>
                <JobFilter
                  getJobList={getJobList}
                  register={register}
                  handleSubmit={handleSubmit}
                  reset={reset}
                  control={control}
                  getValues={getValues}
                  watch={watch}
                />
              </Col>

              <Col lg={9} md={8}>
                <Row>
                  <JobCard cx={cx} jobList={jobList} getJobList={getJobList} />
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </form>

      <JobToggle
        show={showJobToggle}
        handleJobToggleClose={handleJobToggleClose}
        getJobList={getJobList}
        register={register}
        handleSubmit={handleSubmit}
        reset={reset}
        control={control}
        getValues={getValues}
        watch={watch}
      />
    </>
  );
};

export default Job;
