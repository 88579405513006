import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { profile } from "../../../assets/images";
import { MdEdit } from "react-icons/md";
import { PiStar, PiStarFill } from "react-icons/pi";
import { useForm } from "react-hook-form";
import { tokenIsExpired } from "../../../utils/tokenIsExpired";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MyProfile = () => {
  const [phoneOtpVerify, setPhoneOtpVerify] = useState("");
  const [phoneOTP, setPhoneOTP] = useState("");
  const [emailOtpVerify, setEmailOtpVerify] = useState("");
  const [emailOTP, setEmailOTP] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();

  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      const { data } = await response.json();
      localStorage.setItem("userData", JSON.stringify(data));
      reset({
        firstName: data?.firstName,
        lastName: data?.lastName,
        uploadImages: data?.uploadImages
          ? data?.uploadImages
          : data?.sso_profile_image,
        profileImage: data?.uploadImages
          ? data?.uploadImages
          : data?.sso_profile_image,
        city: data?.city,
        state: data?.state,
        email: data?.email,
        phoneNo: data?.phoneNo,
      });
    } catch (error) {
      console.error(error);
    }
  }

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    phoneNo,
    uploadImages,
    profileImage,
    city,
    state,
  }: any) => {
    try {
      const formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("phoneNo", phoneNo);
      formData.append("uploadImages", uploadImages[0] ?? profileImage);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${JSON.parse(localStorage.getItem("userData")!)?.id
        }/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      const { data } = await response.json();

      reset({
        firstName: data?.firstName,
        lastName: data?.lastName,
        uploadImages: data?.uploadImages
          ? data?.uploadImages
          : data?.sso_profile_image,
        city: data?.city,
        state: data?.state,
        email: data?.email,
        phoneNo: data?.phoneNo,
      });
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getProfile();
    }
  };

  const phoneNumberUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("userData")!)?.id.toString()
      );
      formData.append("phoneNo", "+91" + getValues("phoneNo"));

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/phone-update/`,
        {
          method: "POST",

          body: formData,
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      const { data } = await response.json();
      setPhoneOtpVerify("otp");
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const verifyPhoneOtp = async () => {
    try {
      const formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("userData")!)?.id.toString()
      );
      formData.append("otp_phone", phoneOTP);
      formData.append("phoneNo", "+91" + getValues("phoneNo"));

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/phone-update-otp-verify/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      const { data } = await response.json();
      reset({
        phoneNo: getValues("phoneNo"),
      });
      const json_data = JSON.parse(localStorage.getItem("userData")!);
      json_data.phoneNo = "+91" + getValues("phoneNo");
      localStorage.setItem("userData", JSON.stringify(json_data));
      setPhoneOtpVerify("");
      setPhoneOTP("");
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getProfile();
    }
  };
  const emailUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("email", getValues("email"));
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("userData")!)?.id.toString()
      );
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login-signup/email-update/`,
        {
          method: "POST",
          body: formData,
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      const { data } = await response.json();
      setEmailOtpVerify("otp");
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const verifyEmailOtp = async () => {
    try {
      const formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("userData")!)?.id.toString()
      );
      formData.append("otp_email", emailOTP);
      formData.append("email", getValues("email"));

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login-signup/email-update-otp-verify/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      const { data } = await response.json();
      reset({
        email: getValues("email"),
      });
      const json_data = JSON.parse(localStorage.getItem("userData")!);
      json_data.email = getValues("email");
      localStorage.setItem("userData", JSON.stringify(json_data));
      setEmailOtpVerify("");
      setEmailOTP("");
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getProfile();
    }
  };

  useEffect(() => {
    getProfile();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${cx.edit_prodfileSec} ${cx.pageSpace}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Col md={12} sm={12}>
              <div className={`${cx.profile_main}`}>
                <h2>My Profile</h2>
                <div className={`${cx.userImage}`}>
                  <img
                    src={getValues("profileImage") ?? profile}
                    alt="user-review"
                  />
                  <div className={`${cx.editIcon}`}>
                    <div className={`${cx.edit}`}>
                      <MdEdit />
                      <input
                        type="file"
                        {...register("uploadImages", {
                          required: true,
                          onChange: (event) => {
                            setValue(
                              "profileImage",
                              URL.createObjectURL(event.target.files[0])
                            );
                            trigger(["uploadImages", "profileImage"]);
                          },
                        })}
                      />
                      {errors.uploadImages && (
                        <span>This field is required</span>
                      )}
                    </div>
                  </div>
                </div>

                <Row>
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="first name"
                      {...register("firstName", { required: true })}
                    />
                    {errors.firstName && <span>This field is required</span>}
                  </Col>
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="last name"
                      {...register("lastName", { required: true })}
                    />
                    {errors.lastName && <span>This field is required</span>}
                  </Col>
                  {/* <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>DOB</label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("date", { required: true })}
                    />
                    {errors.date && <span>This field is required</span>}
                  </Col> */}

                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      {(phoneOtpVerify === "editPhone" ||
                        phoneOtpVerify === "") &&
                        "Phone Number"}{" "}
                      {phoneOtpVerify === "otp" && "Phone OTP"}
                      <span className="text-danger">*</span>
                    </label>
                    <div className={`${cx.number_otpBox} position-relative`}>
                      {(phoneOtpVerify === "" ||
                        phoneOtpVerify === "editPhone") && (
                          <input
                            disabled={phoneOtpVerify === "editPhone" ? false : true}
                            type="text"
                            className="form-control"
                            placeholder="999-999-9999"
                            {...register("phoneNo", { required: true })}
                          />
                        )}
                      {phoneOtpVerify === "otp" && (
                        <input
                          disabled={phoneOtpVerify === "otp" ? false : true}
                          type="text"
                          className="form-control"
                          placeholder="999-999-9999"
                          value={phoneOTP}
                          onChange={(event) => {
                            setPhoneOTP(event.target.value);
                          }}
                        />
                      )}
                      {phoneOtpVerify === "" && (
                        <button className={`${cx.otpVerify}`}
                          type="button"
                          onClick={() => {
                            setPhoneOtpVerify("editPhone");
                          }}
                        >
                          {getValues("phoneNo") ? "Edit" : "Add"}
                        </button>
                      )}
                      {phoneOtpVerify === "editPhone" && (
                        <button className={`${cx.otpVerify}`}
                          type="button"
                          onClick={() => {
                            phoneNumberUpdate();
                          }}
                        >
                          Send OTP
                        </button>
                      )}
                      {phoneOtpVerify === "otp" && (
                        <button className={`${cx.otpVerify}`}
                          type="button"
                          onClick={() => {
                            verifyPhoneOtp();
                          }}
                        >
                          Verify Phone OTP
                        </button>
                      )}
                    </div>

                    {errors.phoneNo && <span>This field is required</span>}
                  </Col>

                  {JSON.parse(localStorage.getItem("userData")!)?.provider ===
                    "credentials" ? (
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>
                        {(emailOtpVerify === "editEmail" ||
                          emailOtpVerify === "") &&
                          "Email"}{" "}
                        {emailOtpVerify === "otp" && "Email OTP"}
                        <span className="text-danger">*</span>
                      </label>
                      {(emailOtpVerify === "" ||
                        emailOtpVerify === "editEmail") && (
                          <input
                            disabled={
                              emailOtpVerify === "editEmail" ? false : true
                            }
                            type="email"
                            className="form-control"
                            placeholder="user@example.com"
                            {...register("email", { required: true })}
                          />
                        )}
                      {emailOtpVerify === "otp" && (
                        <input
                          disabled={emailOtpVerify === "otp" ? false : true}
                          type="text"
                          className="form-control"
                          value={emailOTP}
                          onChange={(event) => {
                            setEmailOTP(event.target.value);
                          }}
                        />
                      )}
                      {emailOtpVerify === "" && (
                        <button
                          type="button"
                          onClick={() => {
                            setEmailOtpVerify("editEmail");
                          }}
                        >
                          {getValues("email") ? "Edit" : "Add"}
                        </button>
                      )}
                      {emailOtpVerify === "editEmail" && (
                        <button
                          type="button"
                          onClick={() => {
                            emailUpdate();
                          }}
                        >
                          Send OTP
                        </button>
                      )}
                      {emailOtpVerify === "otp" && (
                        <button
                          type="button"
                          onClick={() => {
                            verifyEmailOtp();
                          }}
                        >
                          Verify Email OTP
                        </button>
                      )}

                      {errors.email && <span>This field is required</span>}
                    </Col>
                  ) : (
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        type="email"
                        className="form-control"
                        placeholder="user@example.com"
                        {...register("email", { required: true })}
                      />
                      {errors.email && <span>This field is required</span>}
                    </Col>
                  )}

                  {/* <Col md={12} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Street Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Street address"
                      {...register("street", { required: true })}
                    />
                    {errors.street && <span>This field is required</span>}
                  </Col> */}
                  {/* <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Apartment Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Apartment number (optional)"
                      {...register("apartment", { required: true })}
                    />
                    {errors.apartment && <span>This field is required</span>}
                  </Col> */}
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      City <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      {...register("city", { required: true })}
                    />
                    {errors.city && <span>This field is required</span>}
                  </Col>
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      State <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      {...register("state", { required: true })}
                    />
                    {errors.state && <span>This field is required</span>}
                  </Col>
                  {/* <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Pin Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pin code"
                      {...register("pincode", { required: true })}
                    />
                    {errors.pincode && <span>This field is required</span>}
                  </Col> */}
                  <Col md={6} className={`m-auto ${st.btnsGroup} text-center`}>
                    <button className={`btn w-100 ${st.fillBtn}`}>
                      Submit
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Container>
        </form>
      </section>

      <div className={`${cx.reviewSection}`}>
        <Container>
          <Row>
            <Col lg={12}>
              <div className={`${cx.contentReview}`}>
                <h2>Latest Review</h2>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MyProfile;
