import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Row } from "react-bootstrap";
import st from "../../../assets/stylesheet/style.module.scss";
import { Container, Col } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  FaFacebookF,
  FaFacebookMessenger,
  FaHeart,
  FaLinkedinIn,
} from "react-icons/fa";
import { MdDateRange, MdPlace } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong, FaTwitter } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { BiDetail, BiSolidTag } from "react-icons/bi";
import { TenderItem } from "../../../constants/Interface";
import { toast } from "react-toastify";

const TenderDetails = () => {
  const [favList, setFavList] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const [tenderDetails, setTenderDetails] = useState<TenderItem>({
    id: 0,
    title: "",
    place: "",
    body: "",
    description_type: "",
    text_description: "",
    file_description: "",
    create_time: "",
    previous: null,
    next: null,
  });

  async function getTenderDetails() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/tender/tender-details/${params.id}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setTenderDetails(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  const favorite = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append("tender", id.toString());
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/tender/fav_tender/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      if (response.status === 204 && response.statusText === "No Content") {
        toast.info("Tender Removed from Favorite");
      }

      if (response.status === 201 && response.statusText === "Created") {
        const data = await response.json();
        toast.success(data.message);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getTenderDetails();
      localStorage.getItem("userData")! && getProfile();
    }
  };
  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      //   const { data,favourite_news,favourite_jobs,favourite_tenders } = await response.json();
      const { favourite_tenders } = await response.json();
      setFavList(favourite_tenders);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  useEffect(() => {
    localStorage.getItem("userData")! && getProfile();
    getTenderDetails();
    window.scrollTo(0, 0);
  }, [params?.id]);

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.pageSpace}`}>
        <Container>
          {/* <Row>
            <Col md={12}>
              <div className={`${cx.title_TopHeading}`}>
                <h4><span>Call For Tender:</span> Rental of tractors for plowing and harrowing in the Kenge and Menkao sites (76 ha including 50 ha in Kenge and 26 ha in Menkao)</h4>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col md={11} className="m-auto">
              <div className={`${cx.post_list_left}`}>
                <div className={`${cx.post_wrapper}`}>
                  {/* <img src={building} alt="news" className={`${cx.Top_img}`} /> */}
                  <div className={`${cx.content_details}`}>
                    <div className={`${cx.tag_line}`}>Call For Tender:</div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <ul className={`${cx.share_icons}`}>
                        <li>
                          <NavLink to={"#"}>
                            <FaFacebookF />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaLinkedinIn />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaFacebookMessenger />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaTwitter />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <IoMdMail />
                          </NavLink>
                        </li>
                      </ul>

                      <div className={`${cx.addFavorite}`}>
                        {/* <NavLink className={`${cx.chatBtn}`} to={"#"}>
                          Chat
                        </NavLink> */}

                        <div className={`${cx.heart}`}>
                          <button
                            className={`${
                              favList?.some(
                                (item: any) => item.id === +params.id!
                              )
                                ? cx.active
                                : ""
                            }`}
                            onClick={() => {
                              if (!localStorage.getItem("userData")!) {
                                toast.error(
                                  "Please login to mark as favorite."
                                );
                              } else {
                                // setFavorite(true);
                                favorite(+params.id!);
                              }
                            }}
                          >
                            <FaHeart /> Add To Favorites
                          </button>
                        </div>
                      </div>
                    </div>
                    <h3>{tenderDetails.title}</h3>
                    <div className="d-lg-flex justify-content-between">
                      <ul className={`${cx.date_comments}`}>
                        <span>
                          {" "}
                          <BiSolidTag className="tender_tag progressClr" />{" "}
                          <small>
                            <b>Published On:</b>
                          </small>
                        </span>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <MdDateRange />
                            {new Date(
                              tenderDetails.create_time!
                            ).toDateString()}
                          </div>
                        </li>
                      </ul>
                      <div className="d-lg-flex d-md-flex d-sm-flex gap-3">
                        <ul className={`${cx.date_comments}`}>
                          <li>
                            <div className={`${cx.flex_box}`}>
                              <span className="text-dark">
                                <b>Start Date:</b>
                              </span>{" "}
                              21 December, 2023
                            </div>
                          </li>
                        </ul>
                        <ul className={`${cx.date_comments}`}>
                          <li>
                            <div className={`${cx.flex_box}`}>
                              <span className="text-dark">
                                <b>End Date:</b>
                              </span>{" "}
                              21 December, 2023
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <hr />
                    <div className={`${cx.place_bodyTender}`}>
                      <h6>
                        <span>
                          <MdPlace /> Place:
                        </span>{" "}
                        {tenderDetails.place}
                      </h6>
                      <h6>
                        <span>
                          <BiDetail /> Body:
                        </span>{" "}
                        {tenderDetails.body}
                      </h6>
                    </div>

                    <div className={`${cx.para_box}`}>
                      {tenderDetails.description_type === "file" && (
                        <div>
                          <img
                            className={`${cx.tender_detailImg}`}
                            src={tenderDetails.file_description}
                            alt="details"
                          />
                        </div>
                      )}

                      {tenderDetails.description_type === "text" && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: tenderDetails.text_description!,
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className={`${cx.content_details}`}>
                    <div
                      className={`${cx.navigation_wrapBtn}`}
                      style={{ cursor: "pointer" }}
                    >
                      {tenderDetails?.previous ? (
                        <span
                          onClick={() => {
                            navigate(
                              `/tender-details/${tenderDetails.previous}`
                            );
                          }}
                          className={`${cx.prev_next}`}
                        >
                          <FaArrowLeftLong /> Prev Post
                        </span>
                      ) : (
                        <></>
                      )}
                      {tenderDetails?.next ? (
                        <span
                          onClick={() => {
                            navigate(`/tender-details/${tenderDetails.next}`);
                          }}
                          className={`${cx.prev_next}`}
                        >
                          Next Post <FaArrowRightLong />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            {/* <Col md={4}>
              <div className={`${cx.post_list_right}`}>
                <div className={`${cx.heading_top}`}>Most Commented</div>
                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img src={building} alt="news" className={`${cx.rightBox_img}`} />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>
                        Politics
                      </div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>Lorem ipsum dolor sit amet, consecteturadip isicing elit, sed do eiusmod
                    tempor incididunt ed do eius.</p>
                </NavLink>
                <hr />

                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img src={building} alt="news" className={`${cx.rightBox_img}`} />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>
                        Politics
                      </div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>Lorem ipsum dolor sit amet, consecteturadip isicing elit, sed do eiusmod
                    tempor incididunt ed do eius.</p>
                </NavLink>
                <hr />

                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img src={building} alt="news" className={`${cx.rightBox_img}`} />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>
                        Politics
                      </div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>Lorem ipsum dolor sit amet, consecteturadip isicing elit, sed do eiusmod
                    tempor incididunt ed do eius.</p>
                </NavLink>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TenderDetails;
