import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom"; // Make sure to import any necessary dependencies
import { FaStar, FaClock, FaHeart, FaEye } from "react-icons/fa"; // Import necessary icons
import cx from "./index.module.scss";
import { IoLocationSharp } from "react-icons/io5";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ProductItem } from "../../../constants/Interface";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { storedSearchResult } from "../../../redux_toolkit/reducer/dataReducer";
// import { tokenIsExpired } from "../../../utils/tokenIsExpired";

const ratings = [1, 2, 3, 4, 5];
const ProductCard = ({
  product,
  getProductList,
  captureSearchState,
  getProfile,
  favList,
}: {
  product: ProductItem;
  getProductList: any;
  captureSearchState?: any;
  getProfile: any;
  favList: any[];
}) => {
  const logoutLinkRef = useRef(null);
  const [startPosition, setStartPosition] = useState(0);

  const goToNext = () => {
    if (product?.product_images?.length === startPosition + 1) {
      setStartPosition(0);
    } else {
      setStartPosition(startPosition + 1);
    }
  };
  const goToPrev = () => {
    if (startPosition === 0) {
      setStartPosition(product?.product_images?.length - 1);
    } else {
      setStartPosition(startPosition - 1);
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    id,
    images,
    user,
    title,
    price = 0,
    description,
    location,
    views,
    created_at,
    product_images,
    avg_rating,
  } = product;

  const options = {
    loop: true,
    items: product?.product_images?.length,
    dots: true,
    nav: true,
    navText: [
      `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`,
      `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`,
    ],
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  const favorite = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append("product", id.toString());
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/fav_product/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        if (response.status === 403) {
          localStorage.clear();
          logoutLinkRef.current.click();
          setTimeout(() => {
            navigate("/login");
          }, 500);
        }
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary

      if (response.status === 204 && response.statusText === "No Content") {
        toast.info("Job Removed from Favorite");
      }

      if (response.status === 201 && response.statusText === "Created") {
        const data = await response.json();
        toast.success(data.msg);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getProductList();
      localStorage.getItem("userData")! && getProfile();
    }
  };

  const handleViewCount = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/${id}/?user_id=${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }
      dispatch(
        storedSearchResult({
          storedSearch: { ...captureSearchState },
        })
      );
      navigate("/product-details/" + id);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      localStorage.getItem("userData")! && getProfile();
    }
  };

  return (
    <div className={`${cx.main_box}`}>
      <div className={`${cx.Topbox_Reatives}`}>
        <div className={`${cx.slider_heartDiv}`}>
          <NavLink
            to={"#"}
            className={`${cx.imgBox}`}
            onClick={() => {
              handleViewCount();
            }}
          >
            <OwlCarousel
              className="owl-theme"
              // ref={carouselRef}
              startPosition={startPosition}
              loop
              margin={10}
              nav
              items={1}
              /*  navText={[
              `<svg
                onClick={() => goToPrev()}
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>`,
              `  <svg
                onClick={() => goToNext()}
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>`,
            ]} */
            >
              {product_images?.map((item) => {
                return (
                  <div
                    className="item"
                    onClick={() => {
                      handleViewCount();
                    }}
                  >
                    <NavLink to={"#"} className={`${cx.ads_imgBox}`}>
                      <img
                        src={item?.image_url}
                        alt="banner"
                        className="img-fluid"
                      />
                    </NavLink>
                  </div>
                );
              })}
            </OwlCarousel>
            {product_images.length > 1 ? (
              <>
                <div className={`${cx.actionarea} ${cx.left_btnSlider}`}>
                  <button className={`btn ${cx.actionBtn}`}>
                    <svg
                      onClick={() => goToPrev()}
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                    </svg>
                  </button>
                </div>
                <div className={` ${cx.actionarea2} ${cx.left_btnSlider}`}>
                  <button className={`btn ${cx.actionBtn}`}>
                    <svg
                      onClick={() => goToNext()}
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                    </svg>
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
            {/* <img src={banner1} alt="banner" /> */}
          </NavLink>
          <div className={`${cx.heart}`}>
            <button
              className={`${
                favList?.some((item: any) => item.id === id) ? cx.active : ""
              }`}
              onClick={() => {
                if (!localStorage.getItem("userData")!) {
                  toast.error("Please login to mark as favorite.");
                } else {
                  // setFavorite(true);
                  favorite(id);
                }
              }}
            >
              <FaHeart />
            </button>
          </div>
        </div>
        <div
          className={`${cx.contentArea}`}
          onClick={() => {
            handleViewCount();
          }}
        >
          <div className={`${cx.timing_heart}`}>
            <div className={`${cx.time}`}>
              <FaClock />
              {new Date(created_at).toDateString()}
            </div>
          </div>
          <h3>{title}</h3>
          <div className={`${cx.headLine}`}>
            <h4>${price ? +price?.toFixed(2) : 0} USD</h4>
            <div className={`${cx.rating}`}>
              {ratings
                .filter((item: number) => item <= avg_rating)
                .map((item: number) => (
                  <FaStar />
                ))}
            </div>
          </div>
          <p>{description}</p>
          <div className={`${cx.location_views}`}>
            <div className={`${cx.location}`}>
              <IoLocationSharp /> {location}
            </div>
            <div className={`${cx.views}`}>
              <FaEye /> {views}
            </div>
          </div>
        </div>
      </div>

      <a ref={logoutLinkRef} href="/" style={{ display: "none" }}>
        Go to login
      </a>
    </div>
  );
};

export default ProductCard;
