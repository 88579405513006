import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tokenIsExpired } from "../../../utils/tokenIsExpired";
import { useDispatch } from "react-redux";

const ServiceCard = ({ cx, serviceList, getServiceList }: any) => {
  const [favList, setFavList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const favorite = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append("service", id.toString());
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/service/fav_service/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary

      if (response.status === 204 && response.statusText === "No Content") {
        toast.info("Service Removed from Favorite");
      }

      if (response.status === 201 && response.statusText === "Created") {
        const data = await response.json();
        toast.success(data.message);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getServiceList();
      localStorage.getItem("userData")! && getProfile();
    }
  };
  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      //   const { data,favourite_news,favourite_jobs,favourite_tenders } = await response.json();
      const { favourite_services = [] } = await response.json();
      setFavList(favourite_services);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  useEffect(() => {
    localStorage.getItem("userData")! && getProfile();
  }, []);
  console.log(serviceList, "serviceList");
  return (
    <>
      {serviceList.map((service: any) => {
        return (
          <Col lg={4} md={6} key={service?.id}>
            <div className={`${cx.listingPostion}`}>
              <NavLink
                to={`/services-details/${service?.id}`}
                className={`${cx.listingCard}`}
              >
                <small>{service?.category?.name}</small>
                {/* <FaRegStar
                          className={`${cx.starMark}`}
                          onClick={() => {
                            favorite(job.id);
                          }}
                        /> */}
                <h4>{service?.name}</h4>
                <p
                  dangerouslySetInnerHTML={{ __html: service.description! }}
                ></p>
                <span
                  // to={`/job-details/${job?.id}`}
                  className={`${cx.readMore}`}
                >
                  Read more
                </span>
              </NavLink>
              <div className={`${cx.heart}  ${cx.starMark}`}>
                <button
                  className={`${
                    favList?.some((item: any) => item.id === service.id)
                      ? cx.active
                      : ""
                  }`}
                  onClick={() => {
                    if (!localStorage.getItem("userData")!) {
                      // toast.error("Please login to mark as favorite.");
                      navigate("/login");
                    } else {
                      // setFavorite(true);
                      favorite(service.id);
                    }
                  }}
                >
                  <FaHeart />
                </button>
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default ServiceCard;
