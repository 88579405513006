import React, { useEffect, useRef, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col, Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginandChangeHeader } from "../../../redux_toolkit/reducer/registrationReducer";
import { useDispatch } from "react-redux";

const OTP = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const dashboardLinkRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleVerification = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_verification/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            [Object.keys(location.state).includes("email")
              ? "otp_email"
              : "otp_phone"]: otp,
            [Object.keys(location.state).includes("email")
              ? "email"
              : "phoneNo"]: Object.keys(location.state).includes("email")
              ? location.state.email
              : "+91" + location.state.phone,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData?.data) {
          // firstLogin(responseData.token.access);
          localStorage.setItem("token", responseData.token.access);
          localStorage.setItem("userData", JSON.stringify(responseData?.data));
          toast.success("LoggedIn Successfully");
          setTimeout(() => {
            dispatch(loginandChangeHeader());
            // navigate("/");
            dashboardLinkRef.current.click();
          }, 5000);
        }
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData?.error2);
        toast.error("something went wrong");
      }
    } catch (error) {
      toast.error("something went wrong");

      console.error(error, "Error verifying OTP:");
    }
  };

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <div className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col xl={5} lg={6} md={9} sm={12} className={`m-auto`}>
              <div className={`${cx.onboardingBox}`}>
                <div className={`mb-4 ${cx.onboardingHeading}`}>
                  <h1>Verify</h1>
                  <p>
                    We send 4 digit code to your{" "}
                    {Object.keys(location.state).includes("email")
                      ? "email"
                      : "Phone Number"}
                  </p>
                </div>

                <div className={`${cx.formStart}`}>
                  <div className={`${cx.otpField}`}>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}

                  <div className={`${cx.onboardingButton}`}>
                    <Button className={`btn`} onClick={handleVerification}>
                      Verify
                    </Button>{" "}
                    <a
                      ref={dashboardLinkRef}
                      href="/"
                      style={{ display: "none" }}
                    >
                      Go to Dashboard
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OTP;
