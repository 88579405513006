import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Navigation } from "./components/Website";
import "bootstrap/dist/css/bootstrap.css";
import {
  ContactUs,
  CreateAccount,
  CreateAccountOTP,
  ForgotPassword,
  Home,
  Home1,
  Job,
  JobDetails,
  JobPostForm,
  Login,
  MyFavourites,
  MyOffers,
  MyProfile,
  News,
  NewsPostForm,
  OTP,
  PostAddForm,
  ProductDetails,
  Signup,
  SignupSeller,
  TenderPostForm,
  TermAndConditions,
  AllCategory,
  ServicePostForm,
  Services,
  ServicesDetails,
  NewsDetails,
  Tender,
  TenderDetails,
} from "./pages/Website";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoryData } from "./redux_toolkit/reducer/dataReducer";
import { getToken } from "./redux_toolkit/reducer/registrationReducer";

// import PrivateRoutes from "./Private";

const App = () => {
  const [token, setToken] = useState("");
  const store = useSelector(getToken);
  useEffect(() => {
    setTimeout(() => {
      setToken(localStorage.getItem("token")!);
    }, 2000);
  }, [store]);

  // function firstLogin(authToken: any) {
  //   setToken(authToken);
  // }
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchCategoryData());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Navigation token={token} />}>
        <Route path="/" element={<Home />} />
        <Route path="home-1" element={<Home1 />} />
        <Route path="login" element={<Login />} />
        <Route path="auth-response" element={<Login />} />
        {/* <Route path="AddComments" element={<AddComments />} /> */}
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="signup" element={<Signup />} />
        <Route path="CreateAccount" element={<CreateAccount />} />
        <Route path="CreateAccountOTP" element={<CreateAccountOTP />} />
        <Route path="signup-seller" element={<SignupSeller />} />
        <Route path="otp" element={<OTP />} />
        {/* <Route element={<PrivateRoutes />}> */}
        <Route path="product-details/:id" element={<ProductDetails />} />
        <Route path="post-ad" element={<ProductDetails />} />
        <Route path="profile" element={<MyProfile />} />
        <Route path="favourites" element={<MyFavourites />} />
        {/* <Route path="help-and-support" element={<HelpSupport />} /> */}
        {/* <Route path="chat" element={<Chat />}></Route> */}
        <Route path="offers" element={<MyOffers />} />
        <Route path="sold-products" element={<MyOffers />} />
        <Route path="add-product" element={<PostAddForm />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="term-and-conditions" element={<TermAndConditions />} />
        <Route path="job" element={<Job />} />

        <Route path="news" element={<News />}></Route>
        <Route path="news-details/:id" element={<NewsDetails />}></Route>

        <Route path="tender" element={<Tender />}></Route>
        <Route path="tender-details/:id" element={<TenderDetails />}></Route>

        <Route path="post-a-job" element={<JobPostForm />}></Route>

        <Route path="job-details/:id" element={<JobDetails />}></Route>

        <Route path="post-a-news" element={<NewsPostForm />}></Route>
        <Route path="post-a-tender" element={<TenderPostForm />}></Route>
        <Route path="all-categories" element={<AllCategory />}></Route>
        <Route path="services" element={<Services />}></Route>
        <Route
          path="/services-details/:id"
          element={<ServicesDetails />}
        ></Route>
        <Route path="/post-a-service" element={<ServicePostForm />}></Route>
        {/* </Route> */}
      </Route>
    </Routes>
  );
};

export default App;
