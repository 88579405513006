import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Collapse, Row } from "react-bootstrap";
import st from "../../../assets/stylesheet/style.module.scss";
import { Container, Col, Button } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { building, profile } from "../../../assets/images";
import {
  FaFacebookF,
  FaFacebookMessenger,
  FaHeart,
  FaLinkedinIn,
  FaRegComment,
} from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong, FaTwitter } from "react-icons/fa6";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { IoMdMail } from "react-icons/io";
import { NewsItem } from "../../../constants/Interface";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import PastTimeMention from "../../../utils/PastTimeMention";
import { tokenIsExpired } from "../../../utils/tokenIsExpired";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";

const NewsDetails = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const abortController = new AbortController();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [favList, setFavList] = useState([]);
  const { register, handleSubmit, reset } = useForm();
  const [newsDetails, setNewsDetails] = useState<NewsItem>({
    id: 1,
    title: "",
    news_article: {
      id: 8,
      name: "",
    },
    description: "",
    news_image: "",
    create_time: "",
    update_time: "",
    comments: [],
  });

  const onSubmit = async ({ comment_text }: any) => {
    try {
      const formData = new FormData();
      formData.append("comment_text", comment_text);
      formData.append(
        "user",
        JSON.parse(localStorage.getItem("userData")!).id.toString()
      );
      if (params.id) {
        formData.append("news", params.id);
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/comment/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      if (response.status === 201 && response.statusText === "Created") {
        toast.success("Comment Created Successfully");
      }
      reset();
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getNewsDetails();
    }
  };

  const favorite = async (news_id: number) => {
    try {
      const formData = new FormData();
      formData.append("news", news_id.toString());
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/fav_news/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      if (response.status === 204 && response.statusText === "No Content") {
        toast.info("News Removed from Favorite");
      }

      if (response.status === 201 && response.statusText === "Created") {
        const data = await response.json();
        toast.success(data.message);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getNewsDetails();
      localStorage.getItem("userData")! && getProfile();
    }
  };

  async function getNewsDetails() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/news/${params.id}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      recommendedNews(data?.news_article?.id);
      setNewsDetails(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  // async function getComments() {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASEURL}/news/comment/`
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to submit the data. Please try again.");
  //     }

  //     // Handle response if necessary
  //     const data = await response.json();
  //     setComments(data);
  //     // ...
  //   } catch (error) {
  //     // Capture the error message to display to the user
  //     console.error(error);
  //   }
  // }
  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      //   const { data,favourite_news,favourite_jobs,favourite_tenders } = await response.json();
      const { favourite_news } = await response.json();
      setFavList(favourite_news);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    localStorage.getItem("userData")! && getProfile();
    // getComments();
    getNewsDetails();
  }, []);

  const likeDislike = async (commentid: number, type: number) => {
    try {
      const formData = new FormData();
      formData.append("comment", commentid.toString());
      formData.append("value", type.toString());
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/like_dislke/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      if (response.status === 204 && response.statusText === "No Content") {
        toast.info("News Removed from Favorite");
      }

      if (response.status === 201 && response.statusText === "Created") {
        const data = await response.json();
        toast.success(data.message);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getNewsDetails();
      localStorage.getItem("userData")! && getProfile();
    }
  };

  const recommendedNews = async (category_id: any) => {
    try {
      const formData = new FormData();
      formData.append("category_id", category_id.toString());

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/news-recomend/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setData(data);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };
  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.title_TopHeading}`}>
                <h4>
                  <span>Society:</span> Popular Enthusiasm to Vote Despite
                </h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8}>
              <div className={`${cx.post_list_left}`}>
                <div className={`${cx.post_wrapper}`}>
                  <img
                    src={newsDetails.news_image!}
                    alt="news"
                    className={`${cx.Top_img}`}
                  />
                  <div className={`${cx.content_details}`}>
                    <div className={`${cx.tag_line}`}>
                      {newsDetails.news_article?.name}
                    </div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <ul className={`${cx.share_icons}`}>
                        <li>
                          <NavLink to={"#"}>
                            <FaFacebookF />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaLinkedinIn />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaFacebookMessenger />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaTwitter />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <IoMdMail />
                          </NavLink>
                        </li>
                      </ul>

                      <div className={`${cx.addFavorite}`}>
                        <button
                          className={`${
                            favList?.some(
                              (item: any) => item.id === +params.id!
                            )
                              ? cx.active
                              : ""
                          }`}
                          onClick={() => {
                            if (!localStorage.getItem("userData")!) {
                              toast.error("Please login to mark as favorite.");
                            } else {
                              // setFavorite(true);
                              favorite(+params.id!);
                            }
                          }}
                        >
                          <FaHeart /> Add To Favorites
                        </button>
                      </div>
                    </div>

                    <h3>{newsDetails.title}</h3>
                    <ul className={`${cx.date_comments}`}>
                      <li>
                        <div className={`${cx.flex_box}`}>
                          <MdDateRange />
                          {new Date(newsDetails.create_time!).toDateString()}
                        </div>
                      </li>
                      <li>
                        <div className={`${cx.flex_box}`}>
                          <FaRegComment />
                          {newsDetails.comments.length < 10
                            ? "0" + newsDetails.comments.length
                            : newsDetails.comments.length}{" "}
                          Comments
                        </div>
                      </li>
                    </ul>

                    <div className={`${cx.para_box}`}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: newsDetails.description!,
                        }}
                      />
                    </div>
                  </div>

                  <div className={`${cx.content_details}`}>
                    <div className={`${cx.navigation_wrapBtn}`}>
                      <NavLink to={"#"} className={`${cx.prev_next}`}>
                        <FaArrowLeftLong /> Prev Post
                      </NavLink>
                      <NavLink to={"#"} className={`${cx.prev_next}`}>
                        Next Post <FaArrowRightLong />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${cx.comments_mainBox}`}>
                <div className={`${cx.heading_top}`}>Comments</div>

                {newsDetails?.comments.map((item) => {
                  return (
                    <div className={`${cx.commentsFlex}`}>
                      <div className={`${cx.left_Imgbox}`}>
                        <img src={profile} alt="profile" />
                      </div>
                      <div>
                        <div className={`${cx.right_detailBox}`}>
                          <div className={`${cx.name_date}`}>
                            <h6>{item?.user?.userName}</h6>
                            <span>
                              <PastTimeMention createdAt={item?.created_at} />
                            </span>
                          </div>
                          <p
                            className={`${cx.content_text}`}
                            dangerouslySetInnerHTML={{
                              __html: item?.comment_text,
                            }}
                          ></p>
                          <div className={`${cx.likeDislike_reply}`}>
                            <div className={`${cx.like_btn}`}>
                              <button
                                onClick={() => {
                                  if (!localStorage.getItem("userData")!) {
                                    toast.error(
                                      "Please login to mark as favorite."
                                    );
                                  } else {
                                    // setFavorite(true);
                                    likeDislike(item?.id!, 1);
                                  }
                                }}
                              >
                                <AiOutlineLike />
                              </button>
                              <small>{item?.total_likes}</small>
                            </div>
                            <div className={`${cx.dislike_btn}`}>
                              <button
                                onClick={() => {
                                  if (!localStorage.getItem("userData")!) {
                                    toast.error(
                                      "Please login to mark as favorite."
                                    );
                                  } else {
                                    // setFavorite(true);
                                    likeDislike(item?.id!, -1);
                                  }
                                }}
                              >
                                <AiOutlineDislike />
                              </button>
                            </div>
                            <div className={`${cx.reply_btn}`}>
                              <Button>Reply</Button>
                            </div>
                            <div className={`${cx.report_btn}`}>
                              <Button>Report Abuse</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <hr />
              </div>

              <div className={`${cx.comments_mainBox}`}>
                <div className={`${cx.heading_top}`}>Write Your Comment</div>

                <div className={`${cx.messageForm_box}`}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Col md={12} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Message:</label>
                      <textarea
                        className="form-control"
                        style={{ minHeight: "80px" }}
                        {...register("comment_text", { required: true })}
                      ></textarea>
                    </Col>
                    <button type="submit" className="btn">
                      Post
                    </button>
                  </form>
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className={`${cx.post_list_right}`}>
                <div className={`${cx.heading_top}`}>Recommended</div>
                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img
                      src={building}
                      alt="news"
                      className={`${cx.rightBox_img}`}
                    />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>Politics</div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>
                    Lorem ipsum dolor sit amet, consecteturadip isicing elit,
                    sed do eiusmod tempor incididunt ed do eius.
                  </p>
                </NavLink>
                <hr />

                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img
                      src={building}
                      alt="news"
                      className={`${cx.rightBox_img}`}
                    />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>Politics</div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>
                    Lorem ipsum dolor sit amet, consecteturadip isicing elit,
                    sed do eiusmod tempor incididunt ed do eius.
                  </p>
                </NavLink>

                <hr />
                <NavLink to={"#"} className={`${cx.recommend_Smallbox}`}>
                  <div className={`${cx.flex_box}`}>
                    <div className={`${cx.left_sideImg}`}>
                      <img
                        src={building}
                        alt="news"
                        className={`${cx.ImgBox}`}
                      />
                    </div>
                    <div className={`${cx.right_sideDetail}`}>
                      <h4>Popular Enthusiasm to Vote Despite Some</h4>
                      <ul className={`${cx.date_comments}`}>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <MdDateRange />
                            21 December, 2023
                          </div>
                        </li>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <FaRegComment />
                            06
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </NavLink>

                <hr />
                <NavLink to={"#"} className={`${cx.recommend_Smallbox}`}>
                  <div className={`${cx.flex_box}`}>
                    <div className={`${cx.left_sideImg}`}>
                      <img
                        src={building}
                        alt="news"
                        className={`${cx.ImgBox}`}
                      />
                    </div>
                    <div className={`${cx.right_sideDetail}`}>
                      <h4>Popular Enthusiasm to Vote Despite Some</h4>
                      <ul className={`${cx.date_comments}`}>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <MdDateRange />
                            21 December, 2023
                          </div>
                        </li>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <FaRegComment />
                            06
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </NavLink>

                <hr />
                <NavLink to={"#"} className={`${cx.recommend_Smallbox}`}>
                  <div className={`${cx.flex_box}`}>
                    <div className={`${cx.left_sideImg}`}>
                      <img
                        src={building}
                        alt="news"
                        className={`${cx.ImgBox}`}
                      />
                    </div>
                    <div className={`${cx.right_sideDetail}`}>
                      <h4>Popular Enthusiasm to Vote Despite Some</h4>
                      <ul className={`${cx.date_comments}`}>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <MdDateRange />
                            21 December, 2023
                          </div>
                        </li>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <FaRegComment />
                            06
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NewsDetails;
