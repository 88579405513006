import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from "react-hook-form";
import Select from "react-select";
import { TypeOptions, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";

const PostAddForm = () => {
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;

  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [dynamicForm, setDynamicForm] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const toastId = React.useRef(null);

  const notify = () => {
    toastId.current = toast("Please Wait .....", {
      autoClose: false,
      closeButton: false, // Remove the closeButton
    });
  };

  const update = (type: TypeOptions, message: string) => {
    toast.update(toastId.current, {
      render: message,
      type: type,
      autoClose: 3000,
      className: "rotateY animated", // The closeButton defined on ToastContainer will be used
    });
  };

  const nextStep = async ({ category_id, sub_category_id, setValue }) => {
    const form = await getDynamicFormByCatAndSubCat({
      category_id,
      sub_category_id,
    });
    if (form && Object.keys(form).length) {
      setTimeout(() => {
        setStep(step + 1);
      }, 1000);
    } else {
      setValue("category", "");
      setValue("sub_category", "");
    }
  };

  const secondNextStep = () => setStep(step + 1);

  const prevStep = () => setStep(step - 1);

  const onSubmit = async ({
    title,
    category,
    sub_category,
    images,
    price,
    description,
    location,
    municipality,
    ...rest
  }: any) => {
    setIsSubmit(true);
    notify();
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("category", category.value);
      if (sub_category.value)
        formData.append("sub_category", sub_category.value);
      Array.from(images).forEach((image: any) => {
        formData.append("uploaded_images", image!);
      });
      // formData.append("images", images);
      formData.append("price", price);
      formData.append("description", description);
      formData.append("location", location);
      formData.append("municipality", municipality);
      formData.append("product_form_data", JSON.stringify(rest));
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      // console.log(data, "Product Post id");
      if (response.status === 201 && response.statusText === "Created") {
        setTimeout(() => {
          update("success", "Product Post Created Successfully");
        }, 500);
      }
      sendTelegramLink(data?.id);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
      update("error", "Something went Wrong");
    } finally {
      setIsSubmit(false);
    }
  };

  async function sendTelegramLink(id: number) {
    const formData = new FormData();
    formData.append("title", id.toString());
    const response = await fetch(
      `${process.env.REACT_APP_BASEURL}/product/product-link/`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")!}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      throw new Error("Failed to send the telegram link. Please try again.");
    }
    const data = await response.json();
    console.log(data, "telegram link send succesfully");
    if (response.status === 200 && response.statusText === "OK") {
      reset();
      setTimeout(() => {
        navigate(`/product-details/${id}`);
      }, 1000);
    }
  }

  async function getDynamicFormByCatAndSubCat({
    category_id,
    sub_category_id,
  }) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/dynamic-form/?category_id=${category_id}&sub_category_id=${sub_category_id}`
      );

      if (!response.ok) {
        const data = await response.json();
        toast.error(data.message || "Something went wrong!");
        throw new Error(data.message || response.status);
      }

      // Handle response if necessary
      const data = await response.json();
      if (data && data[0]?.sub_category_form_status === true) {
        setDynamicForm(data?.[0]?.sub_category_form);
      } else if (
        data &&
        data[0]?.sub_category_form_status === false &&
        data[0]?.category_form_status === true
      ) {
        setDynamicForm(data?.[0]?.category_form);
      } else if (
        data &&
        data[0]?.sub_category_form_status === true &&
        data[0]?.category_form_status === true
      ) {
        setDynamicForm(data?.[0]?.sub_category_form);
      }

      return data;
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    // autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>
     
      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>Add Product</h1>
              </div>

            </Col>

          </Row>
        </Container>
      </section>

      <section className={`${cx.productForm_section} ${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col md={9} className="m-auto">
              <div className={`${cx.product_mainbox}`}>
                <div className={`${cx.step_progress}`}>
                  <ul>
                    <li>
                      <div
                        className={`${cx.stepp} ${cx.one} ${cx.active}`}
                        style={{ backgroundColor: "#f12721" }}
                      >
                        <span className={`${cx.step_number}`}>1</span>
                        <span
                          className={`${cx.line}`}
                          style={{ backgroundColor: "#f12721" }}
                        >
                          &nbsp;
                        </span>
                      </div>
                    </li>
                    <li>
                      <div
                        className={`${cx.stepp} ${cx.two} ${
                          step >= 2 && cx.active
                        }`}
                        style={{ backgroundColor: "#f12721" }}
                      >
                        <span className={`${cx.step_number}`}>2</span>
                        <span
                          className={`${cx.line}`}
                          style={{ backgroundColor: "#f12721" }}
                        >
                          &nbsp;
                        </span>
                      </div>
                    </li>
                    <li>
                      <div
                        className={`${cx.stepp} ${cx.two} ${
                          step >= 3 && cx.active
                        }`}
                        style={{ backgroundColor: "#f12721" }}
                      >
                        <span className={`${cx.step_number}`}>3</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {step === 1 && (
                      <Step1 nextStep={nextStep} errors={errors} />
                    )}

                    {step === 2 && (
                      <Step2
                        nextStep={secondNextStep}
                        prevStep={prevStep}
                        dynamicForm={dynamicForm}
                        errors={errors}
                      />
                    )}
                    {step === 3 && (
                      <Step3 prevStep={prevStep} isSubmit={isSubmit} />
                    )}
                  </form>
                </FormProvider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const Step1 = ({ nextStep, errors }: any) => {
  const [category, setCategory] = useState([]);
  const [subCategory, setSubcategory] = useState([]);

  const { register, handleSubmit, control, watch, setValue } = useFormContext();

  const onSubmit = (data: any) => {
    nextStep({
      category_id: watch("category")?.value ? watch("category")?.value : "",
      sub_category_id: watch("sub_category")?.value
        ? watch("sub_category")?.value
        : "",
      setValue,
    });
  };

  async function getCategory() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/category/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      const options = data
        .map((item: { id: number; category_name: string }) => ({
          value: item?.id,
          label: item?.category_name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setCategory(options);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getSubcategory(id: number) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/sub_category/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      const categorySpecific = data
        .filter(
          (item: {
            id: number;
            category: { id: number };
            subcategory_name: string;
          }) => item.category.id === id
        )
        .map(
          (item: {
            id: number;
            category: { id: number };
            subcategory_name: string;
          }) => ({
            value: item?.id,
            label: item?.subcategory_name,
          })
        )
        .sort((a, b) => a.label.localeCompare(b.label));
      setValue("sub_category", "");
      setSubcategory(categorySpecific);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <Row>
        <Col md={12}>
          <div className={`${cx.form_heading}`}>
            <h3>Choose Category</h3>
          </div>
        </Col>
        <Col md={6} className={`${st.formBox}`}>
          <label className={`${st.labelTitle}`}>Category:</label>
          <Controller
            name="category"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field }) => (
              <Select
                {...field}
                isSearchable={false}
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={category}
                onChange={(event) => {
                  field.onChange(event);
                  getSubcategory(event.value);
                }}
              />
            )}
          />
          {/* {Object.keys(errors).includes("category") && (
            <p style={{ color: "red" }}>This Field is Required</p>
          )} */}
        </Col>
        <Col md={6} className={`${st.formBox}`}>
          <label className={`${st.labelTitle}`}> Sub-category:</label>
          <Controller
            name="sub_category"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isSearchable={false}
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={subCategory}
                onChange={(event) => {
                  field.onChange(event);
                }}
              />
            )}
          />
          {/* {Object.keys(errors).includes("subcategory") && (
            <p style={{ color: "red" }}>This Field is Required</p>
          )} */}
        </Col>
        <Col md={12} className={`${st.formBox}`}>
          <label className={`${st.labelTitle}`}> Product Title</label>
          <input
            type="text"
            {...register("title", { required: true })}
            className="form-control"
            placeholder="Product-title"
          />
          {errors.title && (
            <span style={{ color: "red" }}>This field is required</span>
          )}
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className={`${cx.nextBack_btn}`}>
            <div></div>
            <button className="btn" onClick={handleSubmit(onSubmit)}>
              Continue
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const Step2 = ({ nextStep, prevStep, dynamicForm, errors }: any) => {
  const { register, control, trigger } = useFormContext();

  return (
    <>
      <Row>
        <Col md={12}>
          <div className={`${cx.form_heading}`}>
            <h3>General data</h3>
          </div>
        </Col>

        {dynamicForm?.map((form: any) => {
          if (form?.fieldType?.value === "input") {
            return (
              <Col md={6} className={`${st.formBox}`}>
                <label className={`${st.labelTitle}`}>
                  {form?.fieldHeading}:
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register(`${form?.fieldHeading}`, {
                    required: form?.fieldRequired,
                  })}
                />
              </Col>
            );
          }
          if (form?.fieldType?.value === "textarea") {
            return (
              <Col md={12} className={`${st.formBox}`}>
                <label className={`${st.labelTitle}`}>
                  {" "}
                  {form?.fieldHeading}:
                </label>
                <textarea
                  rows={4}
                  className="form-control"
                  {...register(`${form?.fieldHeading}`)}
                />
              </Col>
            );
          }
          if (form?.fieldType?.value === "dropdown") {
            return (
              <Col md={6} className={`${st.formBox}`}>
                <label className={`${st.labelTitle}`}>
                  {form?.fieldHeading}:
                </label>
                <Controller
                  name={`${form?.fieldHeading}`}
                  control={control}
                  rules={{
                    required: "This field is required",
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isSearchable={false}
                      className="react-dropdown"
                      classNamePrefix="dropdown"
                      options={form?.nestedOptions}
                      onChange={(event) => {
                        field.onChange(event);
                      }}
                    />
                  )}
                />
                {/* {Object.keys(errors).includes("category") && (
            <p style={{ color: "red" }}>This Field is Required</p>
          )} */}
              </Col>
            );
          }

          if (form?.fieldType?.value === "date") {
            return (
              <Col md={6} className={`${st.formBox}`}>
                <label className={`${st.labelTitle}`}>
                  {form?.fieldHeading}:
                </label>
                <input
                  type="date"
                  className="form-control"
                  {...register(`${form?.fieldHeading}`)}
                />
              </Col>
            );
          }

          if (form?.fieldType?.value === "time") {
            return (
              <Col md={6} className={`${st.formBox}`}>
                <label className={`${st.labelTitle}`}>
                  {form?.fieldHeading}:
                </label>
                <input
                  type="time"
                  className="form-control"
                  {...register(`${form?.fieldHeading}`)}
                />
              </Col>
            );
          }
          if (form?.fieldType?.value === "radio") {
            return (
              <Col md={6} className={`${st.formBox}`}>
                <p className="mb-1">
                  <b>{form?.fieldHeading}:</b>
                </p>
                <div className="d-flex gap-3">
                  {form?.nestedOptions?.map((r: any) => {
                    return (
                      <>
                        <div className="d-flex gap-1">
                          <label
                            className={`${st.labelTitle} mb-0`}
                            htmlFor={`${r.value}`}
                          >
                            {r.value}:
                          </label>
                          <input
                            {...register(`${form?.fieldHeading}`)}
                            id={`${r.value}`}
                            type="radio"
                            value={`${r.value}`}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </Col>
            );
          }
          return <></>;
        })}
      </Row>

      <Row>
        <Col md={6} className={`${st.formBox}`}>
          <label className={`${st.labelTitle}`}>Price:</label>
          <input
            type="number"
            className="form-control"
            placeholder="Price"
            {...register("price", { required: true })}
          />
          {errors.price && (
            <span style={{ color: "red" }}>This field is required</span>
          )}
        </Col>
        <Col md={6} className={`${st.formBox}`}>
          <label className={`${st.labelTitle}`}>City:</label>
          <input
            type="text"
            className="form-control"
            placeholder="location"
            {...register("location", { required: true })}
          />
          {errors.location && (
            <span style={{ color: "red" }}>This field is required</span>
          )}
        </Col>

        <Col md={12} className={`${st.formBox}`}>
          <label className={`${st.labelTitle}`}>Municipality:</label>
          <input
            type="text"
            className="form-control"
            placeholder="Municipality"
            {...register("municipality", { required: true })}
          />
          {errors.municipality && (
            <span style={{ color: "red" }}>This field is required</span>
          )}
        </Col>
        <Col md={12} className={`${st.formBox}`}>
          <label className={`${st.labelTitle}`}>Description:</label>
          <textarea
            className="form-control h-auto"
            rows={4}
            placeholder="Type here..."
            {...register("description", { required: true })}
          ></textarea>
          {errors.description && (
            <span style={{ color: "red" }}>This field is required</span>
          )}
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className={`${cx.nextBack_btn}`}>
            <button className="btn" type="button" onClick={prevStep}>
              Back
            </button>
            <button
              className="btn"
              type="button"
              onClick={() => {
                if (Object.keys(errors).length === 0) {
                  trigger(["price", "description", "location", "municipality"]);
                  setTimeout(() => {
                    if (Object.keys(errors).length === 0) {
                      nextStep();
                    }
                  }, 500);
                }
              }}
            >
              Next
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};
const Step3 = ({ nextStep, prevStep, dynamicForm, isSubmit }: any) => {
  const {
    register,
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const [images, setImages] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [uploadImagesError, setUploadImagesError] = useState("");

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length + images.length > 10) {
      setUploadImagesError("You can only upload a maximum of 10 images");
      return;
    }
    setImageFiles((prevImageFiles) => [...prevImageFiles, ...selectedFiles]);

    const newImages = selectedFiles.map((file: File, index) => {
      return { image_url: URL.createObjectURL(file) };
    });

    setImages((prevImages) => [...prevImages, ...newImages]);
    setUploadImagesError("");
  };

  const handleRemoveImage = (index) => {
    setDeleteImages((prevImgObj) => [
      ...prevImgObj,
      ...images.filter((_, i) => i === index),
    ]);
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageFiles((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  return (
    <>
      <Row>
        <Col md={12}>
          <div className={`${cx.form_heading}`}>
            <h3>General data</h3>
          </div>
        </Col>

        <Col md={12} className={`${st.formBox}`}>
          <label className={`${st.labelTitle} mb-2`}>
            Upload Product Images: (Max:10)
          </label>
          <div className={`${st.file_inputContainer}`}>
            {images.map((image, index) => (
              <div key={index} className={`${st.imgs_preview} cursor-pointer`}>
                <img
                  src={image?.image_url}
                  alt={`uploaded ${index}`}
                  className={`${st.imgshow}`}
                />
                <button
                  className={`${st.removeButton}`}
                  onClick={() => handleRemoveImage(index)}
                >
                  <RxCross2 />
                </button>
              </div>
            ))}

            <label className={`${st.custom_fileInput}`}>
              <input
                type="file"
                className="form-control"
                id="fileInput"
                accept="image/*"
                style={{ display: "none" }}
                multiple
                {...register("images", {
                  required: images.length > 0 ? false : true,
                  onChange: (e) => handleImageChange(e),
                })}
              />
              <span className={`${st.plusIcon}`}>
                <FaPlus />
              </span>
            </label>
          </div>
          {errors.images && (
            <span className="text-danger">Product Image is required</span>
          )}
          {uploadImagesError && (
            <span className="text-danger">{uploadImagesError}</span>
          )}
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className={`${cx.nextBack_btn}`}>
            <button className="btn" type="button" onClick={prevStep}>
              Back
            </button>
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary mr-1"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Submit
            </button>
            {/* <button className="btn" type="submit" disabled={isSubmit}>
              Submit
            </button> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PostAddForm;
