import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Pagination, Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TenderTable from "../../../components/Website/TenderTable/TenderTable";
import { NavLink, useNavigate } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";
import SwiperCarousel from "../../../components/Website/SwiperCarousel/SwiperCarousel";
import { useDispatch, useSelector } from "react-redux";
import {
  getHeroBannerAdsIndex,
  handleHerroBannerAdsIndex,
} from "../../../redux_toolkit/reducer/dataReducer";

const Tender = () => {
  const navigate = useNavigate();
  const [tenderList, setTenderList] = useState([]);
  const { register, handleSubmit } = useForm();
  const [heroBannerAds, setHeroBannerAds] = useState<any[]>([]);

  const onSubmit = ({ title, body }: any) => {
    // Handle the form submission
    getTenderList(title ?? "", body ?? "");
  };

  async function getTenderList(title = "", body = "") {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/tender/tender-details/?title__contains=${title}&body__contains=${body}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setTenderList(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getHeroBannerAdsList();
    getTenderList();
    window.scrollTo(0, 0);
  }, []);

  async function getHeroBannerAdsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/hero-banner-ads/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setHeroBannerAds(data);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  const displayMsg = (redirectURL: string) => {
    setTimeout(() => {
      if (localStorage.getItem("token")) {
        navigate(redirectURL);
      } else {
        navigate("/login");
      }
    }, 400);
  };

  const dispatch = useDispatch();
  const ActiveHeroBannerIndex = useSelector(getHeroBannerAdsIndex);

  const handleSlideChange = (swiper) => {
    dispatch(handleHerroBannerAdsIndex(swiper.activeIndex));
  };

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <SwiperCarousel
        handleSlideChange={handleSlideChange}
        heroBannerAds={heroBannerAds}
        ActiveHeroBannerIndex={ActiveHeroBannerIndex}
        upsideDownHeader={"Calls for Tender"}
      />

      <section className={`${cx.searchBox}`}>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
              {/* <Col lg="12" md={12} sm="12"> */}
              {/* <div className="d-flex justify-content-between"> */}
              <Col xl={10} lg={9} md={8} sm="4" xs={4}>
                <div className={`${cx.sarcheHeading}`}>
                  <h3>Search</h3>
                </div>
              </Col>

              <Col xl={2} lg={3} md={4} sm="7" xs={7}>
                <div className={`${cx.post_attachBtn}`}>
                  <button
                    className="btn"
                    type="button"
                    onClick={() => {
                      displayMsg("/post-a-tender");
                    }}
                  >
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    Post New Tender
                  </button>
                  {/* </div> */}
                </div>
              </Col>
              {/* </Col> */}
              <Col xl={5} md={4} sm="11" xs={11} className="m-auto">
                <div className={`${cx.sarcheBar}`}>
                  <input
                    type="search"
                    className={`form-control`}
                    placeholder="Object or reference"
                    {...register("title")}
                  />
                </div>
              </Col>
              <Col xl={5} md={5} sm="11" xs={11} className="m-auto">
                <div className={`${cx.sarcheBar}`}>
                  <input
                    type="search"
                    className={`form-control`}
                    placeholder="Body"
                    {...register("body")}
                  />
                </div>
              </Col>
              <Col xl={2} md={3} sm="11" xs={11} className="m-auto">
                <div className={`${cx.sarcheBar} ${cx.smallDeviceBtn}`}>
                  <button className={`btn smallbtn`} type="submit">
                    Search
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </section>

      <TenderTable
        cx={cx}
        tenderList={tenderList}
        getTenderList={getTenderList}
      />

      <section className={`pt-1 mt-4 mb-5`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.paginationBox}`}>
                <Pagination>
                  <Pagination.First />
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item active>{2}</Pagination.Item>
                  <Pagination.Item disabled>{3}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Tender;
