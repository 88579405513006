import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FaRegComment, FaHeart } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { NewsItem } from "../../../constants/Interface";
import { toast } from "react-toastify";
import imagePath from "../../../hooks/imagePathHandler";
import { tokenIsExpired } from "../../../utils/tokenIsExpired";
import { useDispatch } from "react-redux";

export const NewsCard = ({ cx, newsList, fetchData }: any) => {
  const [favList, setFavList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const favorite = async (news_id: number) => {
    try {
      const formData = new FormData();
      formData.append("news", news_id.toString());
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/fav_news/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      if (response.status === 204 && response.statusText === "No Content") {
        toast.info("News Removed from Favorite");
      }

      if (response.status === 201 && response.statusText === "Created") {
        const data = await response.json();
        toast.success(data.message);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      fetchData();
      localStorage.getItem("userData")! && getProfile();
    }
  };

  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      //   const { data,favourite_news,favourite_jobs,favourite_tenders } = await response.json();
      const { favourite_news } = await response.json();
      setFavList(favourite_news);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    localStorage.getItem("userData")! && getProfile();
  }, []);

  return (
    <>
      {newsList.map(({ id, news_image, news_article, title }: NewsItem) => {
        return (
          <Col key={id} md={6} className="mb-3">
            <div className={`${cx.articles_main}`}>
              <NavLink
                to={`/news-details/${id}`}
                className={`${cx.article_attachBox}`}
              >
                <img
                  src={news_image}
                  alt="news"
                  className={`${cx.apply_img}`}
                />
                <div className={`${cx.content_details}`}>
                  <div className={`${cx.tag_line}`}>{news_article?.name}</div>
                  <h3>{title}</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>
                </div>
              </NavLink>
              <div className={`${cx.addFavorite}`}>
                <button
                  className={`${
                    favList?.some((item: any) => item.id === id) ? cx.active : ""
                  }`}
                  onClick={() => {
                    if (!localStorage.getItem("userData")!) {
                      toast.error("Please login to mark as favorite.");
                    } else {
                      // setFavorite(true);
                      favorite(id);
                    }
                  }}
                >
                  <FaHeart /> Add To Favorites
                </button>
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
};
