import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import jobcx from "../Job/index.module.scss";
import tendercx from "../Tender/index.module.scss";
import servicecx from "../Services/index.module.scss";
import newscx from "../News/index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Nav, Row, Tab } from "react-bootstrap";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsPostcard } from "react-icons/bs";
import { TbIdBadge } from "react-icons/tb";
import { Container, Col } from "react-bootstrap";
import ProductCard from "../../../components/Website/ProductCard";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import JobCard from "../../../components/Website/JobCard/JobCard";
import TenderTable from "../../../components/Website/TenderTable/TenderTable";
import { NewsCard } from "../../../components/Website/NewsCard/NewsCard";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { tokenIsExpired } from "../../../utils/tokenIsExpired";
import ServiceCard from "../../../components/Website/ServiceCard/ServiceCard";
import { MdOutlineHomeRepairService } from "react-icons/md";

const MyFavourites = () => {
  const [jobList, setJobList] = useState([]);
  const [tenderList, setTenderList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      const {
        favourite_jobs,
        favourite_news,
        favourite_tenders,
        favourite_products,
        favourite_services,
      } = await response.json();

      setJobList(favourite_jobs);
      setTenderList(favourite_tenders);
      setNewsList(favourite_news);
      setProductList(favourite_products);
      setServiceList(favourite_services);
      // ...
    } catch (error: any) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getProfile();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${cx.filter_topSlider} ${st.spb} pb-0`}>
        <Container>
          <div className={`${cx.slideMain_box}`}>
            <div className={`${cx.left}`}>
              <Tab.Container
                id="left-tabs-examplefav"
                defaultActiveKey="favFirst"
              >
                <Row>
                  <Col md={12}>
                    <Nav>
                      <Nav.Item>
                        <Nav.Link eventKey="favFirst" className={`${cx.boxes}`}>
                          <BsPostcard />
                          <p>Post</p>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="favService"
                          className={`${cx.boxes}`}
                        >
                          <MdOutlineHomeRepairService />
                          <p>Serices</p>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="favSecond"
                          className={`${cx.boxes}`}
                        >
                          <HiBuildingOffice2 />
                          <p>Jobs</p>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="favThird" className={`${cx.boxes}`}>
                          <TbIdBadge />
                          <p>Tenders</p>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="favFourth"
                          className={`${cx.boxes}`}
                        >
                          <IoNewspaperOutline />
                          <p>News</p>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col md={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="favFirst">
                        <div>
                          <div
                            className={`${cx.favouriteSection} ${cx.pageSpace}`}
                          >
                            <Row>
                              {productList.map((product) => (
                                <Col lg={4} md={6} className="mb-4">
                                  <ProductCard
                                    getProfile={getProfile}
                                    favList={productList}
                                    product={product}
                                    getProductList={getProfile}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="favService">
                        <div className={`${cx.pageSpace}`}>
                          <Col lg={9} md={8}>
                            <Row>
                              <ServiceCard
                                cx={servicecx}
                                serviceList={serviceList}
                                getServiceList={getProfile}
                              />
                            </Row>
                          </Col>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="favSecond">
                        <div className={`${cx.pageSpace}`}>
                          <Col lg={9} md={8}>
                            <Row>
                              <JobCard
                                cx={jobcx}
                                jobList={jobList}
                                getJobList={getProfile}
                              />
                            </Row>
                          </Col>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="favThird">
                        <div className={`${cx.pageSpace}`}>
                          <TenderTable
                            cx={tendercx}
                            tenderList={tenderList}
                            getTenderList={getProfile}
                          />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="favFourth">
                        <div className={`${cx.pageSpace}`}>
                          <Row>
                            <NewsCard
                              cx={newscx}
                              newsList={newsList}
                              fetchData={getProfile}
                            />
                          </Row>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default MyFavourites;
