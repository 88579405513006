import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { HiBuildingOffice } from "react-icons/hi2";
import { JobItem } from "../../../constants/Interface";
import { FaHeart } from "react-icons/fa";
import { toast } from "react-toastify";
import { tokenIsExpired } from "../../../utils/tokenIsExpired";
import { useDispatch } from "react-redux";

const JobDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [JobDetails, setJobDetails] = useState<JobItem>({
    id: 0,
    job_title: "",
    company_name: "",
    package: "",
    subject_area: { name: "", id: 0 },
    industry: { name: "", id: 0 },
    level_of_education: { name: "", id: 0 },
    region: { name: "", id: 0 },
    contract_type: { name: "", id: 0 },
    description: "",
    create_at: "",
    update_at: "",
  });

  async function getJobDetails() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/jobs/${params.id}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setJobDetails(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const [favList, setFavList] = useState([]);

  const favorite = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append("job", id.toString());
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/fav_job/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary

      if (response.status === 204 && response.statusText === "No Content") {
        toast.info("Job Removed from Favorite");
      }

      if (response.status === 201 && response.statusText === "Created") {
        const data = await response.json();
        toast.success(data.message);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getJobDetails();
      localStorage.getItem("userData")! && getProfile();
    }
  };
  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      //   const { data,favourite_news,favourite_jobs,favourite_tenders } = await response.json();
      const { favourite_jobs } = await response.json();
      setFavList(favourite_jobs);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    localStorage.getItem("userData")! && getProfile();
    getJobDetails();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.jobDetail_section} ${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={11} className="m-auto">
              <div className={`${cx.heading_title}`}>
                <div className={`${cx.left_box}`}>
                  <h2>{JobDetails.job_title}</h2>
                  <h4>{JobDetails.company_name}</h4>
                  <div className={`${cx.locationPin}`}>
                    <IoLocationOutline /> {JobDetails.region.name!}
                  </div>
                </div>

                <div className={`${cx.right_box}`}>
                  {/* <div className={`${cx.chat}`}>
                    <NavLink to={"#"}>Chat</NavLink>
                  </div> */}
                  {/* <div className={`${cx.favorite}`}>
                    <NavLink to={"#"}>
                      <MdFavoriteBorder />
                    </NavLink>
                  </div> */}
                  <div className={`${cx.heart} `}>
                    <button
                      className={`${
                        favList?.some((item: any) => item.id === +params.id!)
                          ? cx.active
                          : ""
                      }`}
                      onClick={() => {
                        if (!localStorage.getItem("userData")!) {
                          toast.error("Please login to mark as favorite.");
                        } else {
                          // setFavorite(true);
                          favorite(+params.id!);
                        }
                      }}
                    >
                      <FaHeart />
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={11} className="m-auto">
              <Row>
                <Col lg={8}>
                  <div className={`${cx.about_job}`}>
                    <div className={`${cx.job_detailsBox}`}>
                      <h2>About the job</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: JobDetails.description!,
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className={`${cx.about_salary}`}>
                    <div className={`${cx.avg_slary}`}>
                      <h2>{JobDetails.package}</h2>
                      <p>per annum</p>
                    </div>
                    <ul className={`${cx.type_employ}`}>
                      <li>
                        <div className={`${cx.type_listBox}`}>
                          <div className={`${cx.list_icon}`}>
                            <HiBuildingOffice />
                          </div>
                          <div className={`${cx.type_describe}`}>
                            <h4>{JobDetails.industry.name!}</h4>
                            <p>Industry</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className={`${cx.type_listBox}`}>
                          <div className={`${cx.list_icon}`}>
                            <HiBuildingOffice />
                          </div>
                          <div className={`${cx.type_describe}`}>
                            <h4>{JobDetails.level_of_education.name!}</h4>
                            <p>Education</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className={`${cx.type_listBox}`}>
                          <div className={`${cx.list_icon}`}>
                            <HiBuildingOffice />
                          </div>
                          <div className={`${cx.type_describe}`}>
                            <h4>{JobDetails.contract_type.name!}</h4>
                            <p>Contract</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className={`${cx.type_listBox}`}>
                          <div className={`${cx.list_icon}`}>
                            <HiBuildingOffice />
                          </div>
                          <div className={`${cx.type_describe}`}>
                            <h4>{JobDetails.subject_area.name!}</h4>
                            <p>Subject Area</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default JobDetails;
