import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import cx from "./index.module.scss";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaGooglePlus,
  FaDribbble,
  FaFacebook,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { mainHeaderLogo } from "../../../assets/images";

interface headerMain {
  showHeaderClass?: string;
}
export default function Footer(props: headerMain) {
  const [, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <>
      <footer className={`${cx.footerStart}`}>
        <Container>
          <Row>
            <Col md={3}>
              <div className={`${cx.footerWidget}`}>
                {/* <img src={logo} className={` ${cx.logo}`} /> */}
                {/* <h2 className="mb-3">Logo</h2> */}
                <NavLink className={`${cx.logo}`} to={"/"}>
                  <img src={mainHeaderLogo} alt="logo" />
                </NavLink>
                <p>
                  Collins Street West, Victoria Near Bank Road Australia
                  QHR12456.
                </p>
                <div className={`${cx.footerSocial}`}>
                  <ul>
                    <li>
                      <NavLink to="#">
                        <FaFacebook />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        <FaLinkedin />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        <FaGooglePlus />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        <FaTwitter />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        <FaDribbble />
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={8} className="ms-auto">
              <Row>
                <Col md={3} sm={6} xs={6}>
                  <div className={`${cx.footerWidget}`}>
                    <h4>Opportunities</h4>
                    <ul className={`${cx.footerMenu}`}>
                      <li>
                        <NavLink to="#">Office</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Jobs</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Tenders</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Immovable</NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={3} sm={6} xs={6}>
                  <div className={`${cx.footerWidget}`}>
                    <h4>News</h4>
                    <ul className={`${cx.footerMenu}`}>
                      <li>
                        <NavLink to="#">Policy</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Economy</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Culture</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Religion</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Company</NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={3} sm={6} xs={6}>
                  <div className={`${cx.footerWidget}`}>
                    <h4>Media and Trends</h4>
                    <ul className={`${cx.footerMenu}`}>
                      <li>
                        <NavLink to="#">Folders</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Style and Beauty</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">MC Geekl</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">On the Web</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Freeze Frames</NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={3} sm={6} xs={6}>
                  <div className={`${cx.footerWidget}`}>
                    <h4>Information</h4>
                    <ul className={`${cx.footerMenu}`}>
                      <li>
                        <NavLink to="#">Strateges</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Talent</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Works</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Interview</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Analysis</NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <div className={`border-top ${cx.endFooter}`}>
          <Container>
            <Row className="justify-content-between text-center py-3">
              <Col md={3}>
                <div>@2024, congoclick.com</div>
              </Col>

              <Col md={3}>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </Col>

              <Col md={3}>
                <NavLink to="/term-and-conditions">
                  Trems and Conditions
                </NavLink>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
}
