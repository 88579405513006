import React, { useEffect } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Container, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";

const TenderPostForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const myColors = [
    "purple",
    "#785412",
    "#452632",
    "#856325",
    "#963254",
    "#254563",
    "white",
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
  ];

  const onSubmit = async ({
    title,
    place,
    body,
    file_description,
    text_description,
    description_type,
    start_date,
    end_date,
  }: any) => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("place", place);
      formData.append("body", body);
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
      formData.append("description_type", description_type);
      if (description_type === "file") {
        formData.append("file_description", file_description[0]);
      }
      if (description_type === "text") {
        formData.append("text_description", text_description);
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/tender/tender-details/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      if (response.status === 201 && response.statusText === "Created") {
        sendTelegramLink(data?.id);
        toast.success("Tender Post Created Successfully");
      }
      reset();
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  async function sendTelegramLink(id: number) {
    const formData = new FormData();
    formData.append("title", id.toString());
    const response = await fetch(
      `${process.env.REACT_APP_BASEURL}/tender/tender-link/`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")!}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      throw new Error("Failed to send the telegram link. Please try again.");
    }
    const data = await response.json();
    console.log(data, "telegram link send succesfully");
    if (response.status === 200 && response.statusText === "OK") {
      reset();
      setTimeout(() => {
        navigate(`/tender-details/${id}`);
      }, 1000);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    // autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>

      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>Post A Tender</h1>
              </div>

            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.productForm_section} ${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col md={9} className="m-auto">
              <div className={`${cx.product_mainbox}`}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <div className={`${cx.form_heading}`}>
                        <h3>Tender Details</h3>
                      </div>
                    </Col>
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>
                        Tender Title:
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("title", { required: true })}
                      />
                      {errors.title && <span>This field is required</span>}
                    </Col>
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Place:</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("place", { required: true })}
                      />
                      {errors.place && <span>This field is required</span>}
                    </Col>
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Start:</label>
                      <input
                        className="form-control"
                        type="date"
                        {...register("start_date", { required: true })}
                      />
                      {errors.start_date && <span>This field is required</span>}
                    </Col>
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>End:</label>
                      <input
                        className="form-control"
                        type="date"
                        {...register("end_date", { required: true })}
                      />
                      {errors.end_date && <span>This field is required</span>}
                    </Col>
                    <Col md={12} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Body:</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("body", { required: true })}
                      />
                      {errors.body && <span>This field is required</span>}
                    </Col>
                    <Col md={12} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Description:</label>
                      <div className="d-flex gap-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="flexRadioDefault1"
                            {...register("description_type", {
                              required: true,
                            })}
                            value="file"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Upload Image
                          </label>
                        </div>
                        <div className="d-flex gap-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="flexRadioDefault1"
                              {...register("description_type", {
                                required: true,
                              })}
                              value="text"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              Write Your Own
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {watch("description_type") === "text" && (
                      <Col md={12} className={`${st.formBox}`}>
                        <Controller
                          name="text_description"
                          control={control}
                          rules={{
                            required: "Please enter task description",
                          }}
                          render={({ field }) => (
                            <ReactQuill
                              {...field}
                              placeholder={"Write Description"}
                              modules={modules}
                              formats={formats}
                              onChange={(text) => {
                                field.onChange(text);
                              }}
                            />
                          )}
                        />
                      </Col>
                    )}
                    {watch("description_type") === "file" && (
                      <Col md={12} className={`${st.formBox}`}>
                        <input
                          type="file"
                          className="form-control"
                          {...register("file_description", { required: true })}
                        />
                        {errors.file_description && (
                          <span>This field is required</span>
                        )}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className={`${cx.nextBack_btn}`}>
                        <button className="btn" type="submit">
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TenderPostForm;
