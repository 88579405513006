import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Nav, Row, Tab } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import OffersCard from "../../../components/Website/OffersCard";
import { useLocation } from "react-router-dom";
import SoldProductCard from "../../../components/Website/SoldProductCard";
import { ProductItem } from "../../../constants/Interface";

const MyOffers = () => {
  const location = useLocation();
  const [productList, setProductList] = useState<ProductItem[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductList();
  }, []);

  const getProductList = async (values?: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/?user_id=${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const {data} = await response.json();
      setProductList(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  async function markAsSold(id) {
    try {
      const formData = JSON.stringify({
        sold: true,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/${id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
            "Content-Type": "application/json",
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setTimeout(() => {
        getProductList();
      }, 1000);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.offers_Section} ${cx.pageSpace}`}>
        <Container>
          <Col md={12}>
            <div className={`${cx.main_heading} text-center mb-2 pb-1`}>
              <h2>
                {location?.pathname === "/offers"
                  ? "My Offers"
                  : "My Sold Product"}
              </h2>
            </div>
          </Col>
          <Tab.Container id="left-tabs-product" defaultActiveKey="myProduct">
            <Row>
              <Col sm={12}>
                <div className="text-center mb-2">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="myProduct">My Product</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="soldProduct">Sold Product</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="myProduct">
                    <Row>
                      {productList
                        ?.filter((item: any) => item)
                        ?.filter((item: any) => !item.sold)
                        ?.map((product: any, index: number) => (
                          <Col lg={6} md={12} className="m-auto" key={index}>
                            <OffersCard
                              productData={product}
                              getProductList={getProductList}
                              markAsSold={markAsSold}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="soldProduct">
                    <Row>
                      {productList
                        ?.filter((item: any) => item)
                        ?.filter((item: any) => item.sold)
                        ?.map((product: any, index: number) => (
                          <Col lg={6} md={12} className="m-auto" key={index}>
                            <SoldProductCard
                              productData={product}
                              getProductList={getProductList}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
};

export default MyOffers;
