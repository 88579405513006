import { CustomError } from "../hooks/customError";
import { logoutandChangeHeader } from "../redux_toolkit/reducer/registrationReducer";

export async function tokenIsExpired(response, toast, dispatch, navigate) {
  if (!response.ok) {
    let errorMessage = "Failed to submit the data. Please try again.";
    let responseData;

    try {
      responseData = await response.json();
      // Extract error message from the specific structure of the error response
      errorMessage = responseData.errors.detail || errorMessage;
    } catch (parseError) {
      console.error("Failed to parse error response:", parseError);
    }

    // Check if the error indicates token expiration and status code is 403
    if (
      response.status === 403 &&
      (errorMessage.toLowerCase().includes("token has expired") ||
        errorMessage.toLowerCase().includes("token is invalid"))
    ) {
      toast.error(errorMessage);
      setTimeout(() => {
        localStorage.clear();
        dispatch(logoutandChangeHeader());
        navigate("/login"); // Redirect to login page
      }, 500);
    }

    const error = new CustomError(errorMessage);
    error.responseData = responseData;
    throw error;
  }
}
